<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Register v1 -->
      <b-card class="mb-0">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5 mb-2">
          <b-img
            class="w-75"
            fluid
            :src="appLogoWithName"
            alt="Login"
          />
        </div>

        <b-card-text class="mb-2 text-center">
          {{ cardText }}
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="registerForm"
          #default="{ handleSubmit }"
        >
          <b-form
            class="auth-register-form mt-2"
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <!-- email -->
            <b-form-group label="Username">
              <b-form-input
                disabled
                :value="user.user_email"
              />
            </b-form-group>

            <!-- password -->
            <validation-provider
              #default="validationContext"
              name="Password"
              :rules="{ required: true, password }"
            >
              <b-form-group
                :label="labelText"
                label-for="password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="getValidationStateNonBootstrap(validationContext)"
                >
                  <b-form-input
                    id="password"
                    v-model="registrationData.password"
                    :state="getValidationState(validationContext)"
                    :type="passwordFieldType"
                    trim
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- password confirmation -->
            <validation-provider
              #default="validationContext"
              name="Password Confirmation"
              :rules="{ required: true, is: registrationData.password }"
            >
              <b-form-group
                :label="`Confirm ${labelText}`"
                label-for="password_confirmation"
              >
                <b-form-input
                  id="password_confirmation"
                  v-model="registrationData.password_confirmation"
                  :state="getValidationState(validationContext)"
                  type="password"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- submit button -->
            <spinner-button
              :loading="loading"
              variant="primary"
              block
              type="submit"
            >
              {{ buttonText }}
            </spinner-button>
          </b-form>
        </validation-observer>

        <b-card-text
          v-if="type === 'setup'"
          class="text-center mt-2"
        >
          <span>Already have an account? </span>
          <b-link :to="{name:'login'}">
            <span>Sign in instead</span>
          </b-link>
        </b-card-text>
      </b-card>
      <!-- /Register v1 -->
    </div>
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BLink, BCardText, BForm, BImg, BFormInvalidFeedback,
  BFormInput, BFormGroup, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import {
  required, email, password, is,
} from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { $themeConfig } from '@themeConfig'
import { ref, onBeforeMount, computed } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import jwt from '@/auth/jwt/useJwt'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import formValidation from '@core/comp-functions/forms/form-validation'
import SpinnerButton from '@/layouts/components/SpinnerButton.vue'

export default {
  components: {
    // BSV
    BCard,
    BLink,
    BCardText,
    BForm,
    BFormInput,
    BFormGroup,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupAppend,
    BImg,

    SpinnerButton,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  setup() {
    const toast = useToast()
    const loading = ref(false)
    const { appName, appLogoWithName } = $themeConfig.app
    const blankRegistration = {
      password: '',
      password_confirmation: '',
    }
    const blankUser = {
      user_email: '',
      user_first_name: '',
      user_last_name: '',
    }

    const user = ref(JSON.parse(JSON.stringify(blankUser)))

    // Check for our token
    const authToken = router.currentRoute.query.token

    const registrationData = ref(JSON.parse(JSON.stringify(blankRegistration)))

    const { type } = router.currentRoute.meta

    const onSubmit = () => {
      loading.value = true
      const data = {
        token: authToken,
        password: registrationData.value.password,
        password_confirmation: registrationData.value.password_confirmation,
      }

      if (type === 'setup') {
        jwt.register(data)
          .then(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Successfully registered. Please login to continue',
                icon: 'CheckSquareIcon',
                variant: 'success',
              },
            })
            router.replace({
              name: 'login',
            })
          }).catch(error => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong please try again later',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
            loading.value = false
            console.log(error)
          })
      } else {
        jwt.forgotPasswordSubmit(data)
          .then(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Successfully reset your password. Please login to continue',
                icon: 'CheckSquareIcon',
                variant: 'success',
              },
            })
            router.replace({
              name: 'login',
            })
          }).catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong please try again later',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
            loading.value = false
          })
      }
    }

    const responseBlock = response => {
      user.value = response.data.user
    }

    const errorBlock = error => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'There was an error retrieving your information please try again later',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      console.log(error)
    }

    // get our user data
    onBeforeMount(() => {
      if (authToken) {
        const params = { token: authToken }

        if (type === 'setup') {
          jwt.registerSetup(params)
            .then(response => responseBlock(response))
            .catch(error => errorBlock(error))
        } else {
          jwt.forgotPasswordSetup(params)
            .then(response => responseBlock(response))
            .catch(error => errorBlock(error))
        }
      } else {
        router.replace({
          name: 'login',
        })
      }
    })

    const {
      refFormObserver, getValidationState, getValidationStateNonBootstrap,
    } = formValidation(null)

    const buttonText = computed(() => (type === 'setup' ? 'Sign Up' : 'Reset Password'))
    const cardText = computed(() => {
      if (type === 'setup') {
        return 'Please set your password below to complete your account setup.'
      }
      return 'Reset your password below.'
    })

    const labelText = computed(() => (type === 'setup' ? 'Password' : 'New Password'))
    return {
      appName,
      appLogoWithName,
      registrationData,
      user,
      loading,

      refFormObserver,
      getValidationState,
      getValidationStateNonBootstrap,

      onSubmit,

      buttonText,
      cardText,
      labelText,
      type,
    }
  },
  data() {
    return {
      // validation rules
      required,
      email,
      password,
      is,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/pages/page-auth.scss';
</style>
